<template>
    <div class="gw_modal_wrap">
        <div style="width: 40%; display: inline-block">
            <div class="gw_title">{{ $t('main_img') }}</div>
            <button class="reset_btn"
                    @click="[changeType('mainFile'), handleFileRemove(mainFile[0], [])]"
                    v-if="mainFile.length > 0">{{ $t('reset') }}
            </button>
            <div class="gw_upload_wrap upload_center" @click="[changeType('mainFile'), initErrorMsg()]">
                <el-upload
                        action=""
                        list-type="picture-card"
                        :file-list="mainFile"
                        :auto-upload="false"
                        :show-file-list="false"
                        :on-change="onlyOnePhotoChange"
                >
                    <button class="gw_background_box gw_main_box" v-if="mainFile.length === 0"></button>
                    <button v-else class="gw_background_box gw_main_box" style="background-image: none;">
                        <img :src="mainFile[0].org_url" v-if="mainFile.length > 0" alt="">
                    </button>
                </el-upload>
                <div class="check_validator" v-if="validation.hasError('mainFile')">
                    {{ validation.firstError('mainFile') }}
                </div>
            </div>
        </div>
        <div style="width: 60%; display: inline-block; vertical-align: top"
             :class="{'object_hidden': !checkPremium()}">
            <div class="gw_title">{{ $t('owner_use_set') }}</div>

            <div class="">
                <el-radio-group class="el_radio_box" v-model="owner" @change="parentChange('owner', owner)"
                                :disabled="isCollectData()">
                    <el-radio class="el_radio_btn" :label="1">{{ $t('owner_use') }}</el-radio>
                    <el-radio class="el_radio_btn" :label="0">{{ $t('owner_use_not') }}</el-radio>
                </el-radio-group>

            </div>
            <div class="check_validator mt10">
                <span>{{ $t('owner_update_msg') }}</span>
            </div>
        </div>
        <div class="gw_title">{{ $t('back_img') }}</div>
        <button class="reset_btn"
                @click="[changeType('backgroundFile'), handleFileRemove(backgroundFile[0], [])]"
                v-if="backgroundFile.length > 0">{{ $t('reset') }}
        </button>
        <div class="gw_upload_wrap dis-block" @click="[changeType('backgroundFile'), initErrorMsg()]">
            <el-upload
                    action=""
                    list-type="picture-card"
                    :file-list="backgroundFile"
                    :auto-upload="false"
                    :show-file-list="false"
                    :on-change="onlyOnePhotoChange"
            >
                <button class="gw_background_box" v-if="backgroundFile.length === 0"></button>
                <button v-else class="gw_background_box" style="background-image: none;">
                    <img :src="backgroundFile[0].org_url" v-if="backgroundFile.length > 0" alt="">
                </button>
            </el-upload>
            <div class="check_validator" v-if="validation.hasError('backgroundFile')">
                {{ validation.firstError('backgroundFile') }}
            </div>
        </div>

        <div class="gw_title">{{ $t('collect_name') }}</div>
        <div class="gw_input">
            <el-input class="gw_input mb0" :placeholder="$t('collect_name_error')" v-model="title"
                      @input="parentChange('title', title)" maxLength="20"></el-input>
            <div class="check_validator" v-if="validation.hasError('title')">
                {{ validation.firstError('title') }}
            </div>
        </div>

        <div class="writer_info_wrap">
            <h3>{{ $t('collect_info') }}</h3>
            <el-input type="textarea" v-model="info" @input="parentChange('info', info)"
                      maxLength="1000"></el-input>
        </div>

        <div class="gw_btn_wrap">
            <button class="gw_btn save" @click="runSave">{{ $t('save') }}</button>
            <div class="check_validator" v-if="errorMsg != ''">
                {{ errorMsg }}
            </div>
        </div>

    </div>
</template>

<script>
import elFileMixins from "@/mixins/elFileMixins";
import EventBus from "@/utils/event-bus";
import util from "@/mixins/util";
import collectValidator from "@/mixins/validators/collectValidator";
import {mapState} from "vuex";

export default {
    name: "CollectWriteModalLayout",
    mixins: [elFileMixins, collectValidator],
    components: {},
    inject: ['collectWriteSetData'],
    provide() {
        return {}
    },
    props: {
        collectData: {
            default: () => {
            }
        },
    },
    data() {
        return {
            collectionName: '',
            errorMsg: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.settingBeforeData();
    },
    mounted() {
        EventBus.$on('GalleryWriteModalLayoutValidator', this.runValidator)
    },
    beforeDestroy() {
        EventBus.$off('GalleryWriteModalLayoutValidator')
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        settingBeforeData() {
            if (!this.isCollectData()) {
                return false;
            }
            this.title = this.collectData.c_title;
            this.collectWriteSetData('title', this.title);
            this.owner = this.collectData.c_owner;
            this.collectWriteSetData('owner', this.owner);
            this.info = this.collectData.c_intro;
            this.collectWriteSetData('info', this.info);

            if (!util.isEmpty(this.collectData.CollectionFiles)) {
                if (!util.isEmpty(this.collectData.CollectionFiles.BgFile) && !util.isEmpty(this.collectData.CollectionFiles.BgFile[0].a_idx)) {
                    this.backgroundFile = this.collectData.CollectionFiles.BgFile;
                    this.collectWriteSetData('backgroundFile', this.backgroundFile);
                }
                if (!util.isEmpty(this.collectData.CollectionFiles.MainFile) && !util.isEmpty(this.collectData.CollectionFiles.MainFile[0].a_idx)) {
                    this.mainFile = this.collectData.CollectionFiles.MainFile
                    this.collectWriteSetData('mainFile', this.mainFile);
                }

            }
        },
        runValidator(validatorArr = ['title', 'owner']) {
            this.$validate(validatorArr).then(success => {
                if (success) {
                    this.errorMsg = "";
                } else {
                    this.errorMsg = this.$t('all_input');
                }
            })
        },
        parentChange(type, value) {
            this.collectWriteSetData(type, value);
            this.initErrorMsg();
        },
        initErrorMsg() {
            this.errorMsg = "";
        },
        runSave() {
            EventBus.$emit('writeCollect');
        },
        isCollectData() {
            return !util.isEmpty(this.collectData);
        },
        checkPremium() {
            if (!util.isEmpty(this.UserInfo) && this.UserInfo.mb_premium === 1) {
                return true
            }
            return false
        }
    },
    watch: {
        'collectData': {
            deep: true,
            handler: function (val, oldVal) {
                this.settingBeforeData()
            }
        },
    },
}
</script>

<style scoped>

</style>
